import {apiFormatDate, isDate} from './dates'
import {API_ERRORS_MAPPER} from './constants'

const isEmpty = obj => {  for (let key in obj) return false;  return true;}

const getHeaders = (
  method = 'GET',
  token = '',
  body = {},
  headers = {},
  stringify = false,
) => ({
  method: method,
  mode: 'cors',
  cache: 'no-store',
  credentials: 'same-origin',
  headers: {
    ...headers,
    Authorization: 'Bearer ' + token,
    accept: 'application/json',
  },
  body:
    method === 'POST' || method === 'PUT' || method === 'DELETE' && !isEmpty(body)
      ? stringify
        ? JSON.stringify(body)
        : body
      : undefined,
})

const getFiltering = (filters) =>
  filters
    ? Object.keys(filters).reduce((acc, key, index) => {
        const filter = isDate(filters[key])
          ? apiFormatDate(filters[key])
          : filters[key]
        return index === 0
          ? `${acc}${key}=${filter}`
          : `${acc}&${key}=${filter}`
      }, '?')
    : ''

function formatErrorMessage({title, detail}) {
  return [title, detail].filter(Boolean).join(': ')
}

function filterErrorStatusCode(error) {
  return error.status === 400 || error.status === '400'
}

export function* request({
  method,
  url,
  token,
  body,
  filters,
  headers,
  stringify = false,
}) {
  const callConfig = getHeaders(method, token, body, headers, stringify)
  const finalUrl = `${url}${getFiltering(filters)}`

  try {
    const response = yield fetch(finalUrl, callConfig)

    const statusCode = response.status
    const [contentType] = response.headers
      .get('content-type')
      .split(';')
      .slice(0, 1)

    const {data, errors = []} =
      contentType === 'application/json'
        ? yield response.json()
        : {data: yield response.blob()}

    if (statusCode === 400) {
      const validationMessage = errors
        .filter(filterErrorStatusCode)
        .map(formatErrorMessage)
        .join('\n')
      const error = {
        message: `${validationMessage} (Error Code: 400)`,
        code: statusCode,
      }
      throw error
    } else if (statusCode > 300) {
      const {message} =
        API_ERRORS_MAPPER[statusCode] || API_ERRORS_MAPPER.default
      const error = {
        message,
        code: statusCode,
      }
      throw error
    }

    return data
  } catch (error) {
    throw error // Propagate the error back to the calling code
  }
}
