import React from 'react'
import {useDispatch} from 'react-redux'
import {deleteCard} from '../../redux/actions/cards'
import {DangerButton} from '../../components/Button'
import {Modal} from '../../components/Modal'
import ConfirmationDialog from '../../components/ConfirmationDialog'

export const DeleteCards = ({card}) => {
  const [modalOpen, setModalOpen] = React.useState(false)
  const dispatch = useDispatch()

  const handleModalClose = () => {
    setModalOpen(false)
  }

  const handleDelete = () => {
    dispatch(deleteCard({card_id: card.cards?.[0]?.id}))
    handleModalClose()
  }

  return (
    <>
      <DangerButton
        style={{width: '140px', textAlign: 'center'}}
        onClick={() => setModalOpen(true)}>
        Delete Cards
      </DangerButton>
      <Modal isOpen={modalOpen} handleClose={handleModalClose}>
        <ConfirmationDialog
          closeModal={handleModalClose}
          handleContinueAction={handleDelete}
          handleCancelAction={handleModalClose}
          description={'This card will be permanently removed.'}
          cancelActionLabel="cancel"
          continueActionLabel="Delete card"
          variant="danger"
        />
      </Modal>
    </>
  )
}
