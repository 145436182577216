import {
  FETCH_DRIVERS_SUCCESS,
  FETCH_STORES_SUCCESS,
  DOWNLOAD_STORE_REPORT,
  DOWNLOAD_DRIVER_REPORT,
  STOP_LOADING,
  ADD_DRIVER_SUCCESS,
  DELETE_DRIVERS_SUCCESS,
} from '../actions/types'

function adminReducers(state = {}, action = {}) {
  switch (action.type) {
    case FETCH_DRIVERS_SUCCESS:
      return {
        ...state,
        drivers: action.payload,
      }
    case FETCH_STORES_SUCCESS:
      return {
        ...state,
        stores: action.payload,
      }
    case DOWNLOAD_STORE_REPORT:
    case DOWNLOAD_DRIVER_REPORT:
      return {
        ...state,
        downloadingReport: true,
      }
    case STOP_LOADING:
      return {
        ...state,
        downloadingReport: false,
      }
    case ADD_DRIVER_SUCCESS:
      return {
        ...state,
        drivers: [action.payload, ...state.drivers],
      }
    case DELETE_DRIVERS_SUCCESS:
      const successfullyDeletedIds = action.payload.results.successful.map(
        (driver) => driver.id,
      )
      return {
        ...state,
        drivers: state.drivers.filter(
          (driver) => !successfullyDeletedIds.includes(driver.driverId),
        ),
      }
    default:
      return state
  }
}

export default adminReducers
