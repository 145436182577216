import {select, put, takeEvery} from 'redux-saga/effects'

import {
  GET_PAYMENT,
  START_LOADING,
  STOP_LOADING,
  GET_LATEST_TRANSACTION,
  UPDATE_PURCHASE_MODEL_FLAG,
} from '../actions/types'
import {getPaymentSuccess, showError, getLatestTransactionSuccess} from '../actions'
import {getToken} from '../utils'
import {API_PAYMENT_ENDPOINT, API_TRANSACTION_ENDPOINT,API_PAYMENT_MODAL} from '../../constants'
import {request} from '../../utils/api'

export function* fetchPayment({payload: {orderId, stripePaymentIntentId}}) {
  console.log("Fetch payment being called", stripePaymentIntentId);
  const token = yield select(getToken)
  const reqOpt = {
    method: 'GET',
    url: `${API_PAYMENT_ENDPOINT}/${stripePaymentIntentId}`,
    token,
  }
  try {
    yield put({type: START_LOADING})
    const payment = yield request(reqOpt)
    yield put(getPaymentSuccess({payment}))
    yield put({type: STOP_LOADING})
  } catch (error) {
    yield put({type: STOP_LOADING})
    yield put(showError(error))
  }
}

export function* getLatestTransaction({
  payload: {
    orderReference: orderId,
    latestTransactionType: transactionType,
    hideError = false,
  },
}) {
  const token = yield select(getToken)
  const reqOpt = {
    method: 'GET',
    url: `${API_TRANSACTION_ENDPOINT}/${orderId}/${transactionType}`,
    token,
  }
  try {
    yield put({type: START_LOADING})
    const transaction = yield request(reqOpt)
    yield put(getLatestTransactionSuccess({transaction}))
    yield put({type: STOP_LOADING})
  } catch (err) {
    const error = {
      ...err,
      ...(err.message.includes('No CHARGE transaction found') && {
        message: 'No payment found. Please try again.',
      }),
    }
    yield put({type: STOP_LOADING})
    if (!hideError) yield put(showError(error))
  }
}

export function* updatePurchaseModalFlag({payload: {isModalOpen, orderId, successCb}}) {
  const body = {
    isModalOpen,
    orderId,
  }
  const token = yield select(getToken)
  const reqOpt = {
    method: 'PUT',
    url: `${API_PAYMENT_MODAL}`,
    token,
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
    },
  }
  try {
    yield put({type: START_LOADING})

    yield request(reqOpt)

    if (successCb) {
      successCb()
    }

    yield put({type: STOP_LOADING})
  } catch (error) {

    yield put(showError(error))
    yield put({type: STOP_LOADING})
  }
}
  
export function* watchPayments() {
  yield takeEvery(GET_PAYMENT, fetchPayment)
  yield takeEvery(GET_LATEST_TRANSACTION, getLatestTransaction)
  yield takeEvery(UPDATE_PURCHASE_MODEL_FLAG, updatePurchaseModalFlag)
}

export default watchPayments
