import {useCallback, useRef} from 'react'
import {debounce} from '../utils/common'
import {DeliveryFilterOption} from './useDeliveryDateFilter'
import {DELIVERIES_FETCH_LIMIT} from '../utils/constants'

export const useDeliveryIntersectionObserver = ({
  loading,
  hasMoreDeliveries,
  deliveriesOffset,
  dateFilter,
  activeTimeline,
  getFilteredDeliveries,
  setDeliveriesOffset,
  debounceDelay,
}) => {
  const observer = useRef()

  return useCallback(
    (node) => {
      if (loading || !hasMoreDeliveries) return

      if (observer.current) observer.current.disconnect()

      observer.current = new IntersectionObserver(
        debounce(async (entries) => {
          const isIntersecting = entries[0].isIntersecting
          const isTimelineValid =
            activeTimeline === DeliveryFilterOption.ALL ||
            activeTimeline === DeliveryFilterOption.PAST

          if (isIntersecting && hasMoreDeliveries && isTimelineValid) {
            const nextOffset = deliveriesOffset + DELIVERIES_FETCH_LIMIT

            setDeliveriesOffset(nextOffset)
            await getFilteredDeliveries(dateFilter, '', nextOffset)
          }
        }, debounceDelay),
        {rootMargin: '100px'},
      )

      if (node) observer.current.observe(node)
    },
    [
      loading,
      hasMoreDeliveries,
      deliveriesOffset,
      dateFilter,
      activeTimeline,
      setDeliveriesOffset,
      getFilteredDeliveries,
      debounceDelay,
    ],
  )
}
