import {DeliveryFilterOption} from '../../hooks/useDeliveryDateFilter'
import {DELIVERIES_FETCH_LIMIT} from '../../utils/constants'
import {
  GET_DELIVERIES_SUCCESS,
  GET_DELIVERY_SUCCESS,
  GET_DELIVERIES_FAIL,
  DELETE_DELIVERY_SUCCESS,
  CLEAR_CURRENT_DELIVERY,
  ADD_DELIVERY_SUCCESS,
  CANCEL_DELIVERY_SUCCESS,
  SCAN_LABEL_SUCCESS,
  SET_ORDERS,
  ADD_RETURNED_ORDERS,
  UPDATE_DELIVERY_DATA,
  UPDATE_DATE_FILTER,
  GET_ONHOLD_ORDERS_SUCCESS,
  GET_ONHOLD_ORDERS_FAIL,
  ADD_ONHOLD_ORDERS,
  UPDATE_SEARCH_TERM,
  RESET_DELIVERY_FILTERS,
  UPDATE_DELIVERY_TIMELINE,
  UPDATE_NARCOTICS_FILTER,
  DUPLICATE_DELIVERY,
  DUPLICATE_DELIVERY_SUCCESS,
  START_LOADING,
  STOP_LOADING,
  DUPLICATE_DELIVERY_FAIL,
  DELETE_DELIVERY,
  NO_MORE_DELIVERIES,
  CLEAR_DELIVERIES_HISTORY,
} from '../actions/types'

function deliveriesDataReducers(state = {}, action = {}) {
  switch (action.type) {
    case GET_DELIVERIES_SUCCESS:
      console.log('🚀 ~ deliveriesDataReducers ~ state:', state)
      console.log('🚀 ~ deliveriesDataReducers ~ action:', action)
      return {
        ...state,
        userInfo: action.payload,
        history: [...state.history, ...action.deliveries],
        hasMoreDeliveries: action.deliveries.length >= DELIVERIES_FETCH_LIMIT,
      }
    case GET_DELIVERIES_FAIL:
      return {...state, history: [], newDeliveryData: {}}
    case UPDATE_DATE_FILTER:
      return {
        ...state,
        history: [],
        dateFilter: action.payload,
        hasMoreDeliveries: true,
      }
    case GET_DELIVERY_SUCCESS:
      return {...state, selectedDelivery: action.delivery, newDeliveryData: {}}
    case DELETE_DELIVERY:
      console.log('DELETE_DELIVERY action received in reducer:', action)
      console.log('Current state before DELETE_DELIVERY:', state)
      const newState = {...state, deliveryId: action.payload.deliveryId}
      console.log('New state after DELETE_DELIVERY:', newState)
      return newState
    case DELETE_DELIVERY_SUCCESS:
      return {...state}
    case CLEAR_CURRENT_DELIVERY:
      return {...state, selectedDelivery: {}, newDeliveryData: {}}
    case ADD_DELIVERY_SUCCESS:
      return {
        ...state,
        selectedDelivery: action.delivery,
        currentReturns: action.delivery.returnedOrders,
      }
    case DUPLICATE_DELIVERY:
      return {...state, delivery: action.payload}
    case DUPLICATE_DELIVERY_SUCCESS:
      return {...state, delivery: action.payload}
    case DUPLICATE_DELIVERY_FAIL:
      return {...state, delivery: {}}
    case START_LOADING:
      return {...state, loading: true}
    case STOP_LOADING:
      return {...state, loading: false}
    case ADD_RETURNED_ORDERS:
      return {...state, currentReturns: []}
    case ADD_ONHOLD_ORDERS:
      return {...state, onHoldOrders: []}
    case GET_ONHOLD_ORDERS_SUCCESS:
      return {...state, onHoldOrders: action.returns.orders}
    case GET_ONHOLD_ORDERS_FAIL:
      return {...state, onHoldOrders: []}
    case CANCEL_DELIVERY_SUCCESS:
      return {...state, selectedDelivery: {}}
    case SCAN_LABEL_SUCCESS:
      return {...state, newDeliveryData: action.payload}
    case UPDATE_DELIVERY_DATA:
      return {...state, newDeliveryData: action.payload}
    case UPDATE_SEARCH_TERM:
      return {...state, searchTerm: action.term}
    case UPDATE_DELIVERY_TIMELINE:
      return {...state, activeTimeline: action.filter}
    case UPDATE_NARCOTICS_FILTER:
      return {...state, isNarcotics: action.isNarcotics}
    case RESET_DELIVERY_FILTERS:
      return {
        ...state,
        history: [],
        searchTerm: '',
        dateFilter: action.payload,
        activeTimeline: DeliveryFilterOption.ALL,
        hasMoreDeliveries: true,
      }
    case SET_ORDERS:
      return {
        ...state,
        selectedDelivery: {
          ...state.selectedDelivery,
          orders: action.payload,
        },
      }
    case NO_MORE_DELIVERIES:
      console.log('no more deliveries')
      return {...state, hasMoreDeliveries: false}
    case CLEAR_DELIVERIES_HISTORY:
      return {...state, history: [], hasMoreDeliveries: true}
    default:
      return state
  }
}

export default deliveriesDataReducers
