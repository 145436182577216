import React from 'react'
import styled from 'styled-components'

export const FilterTags = ({selectedFilter, onFilterChange}) => {
  return (
    <TagsContainer>
      <Tag
        isSelected={selectedFilter === 'all'}
        onClick={() => onFilterChange('all')}>
        All Cards
      </Tag>
      <Tag
        isSelected={selectedFilter === 'almost-expired'}
        onClick={() => onFilterChange('almost-expired')}>
        Expire Soon
      </Tag>
      <Tag
        isSelected={selectedFilter === 'expired'}
        onClick={() => onFilterChange('expired')}>
        Expired
      </Tag>
    </TagsContainer>
  )
}

const TagsContainer = styled.div`
  display: flex;
  gap: 8px;
`

const Tag = styled.button`
  padding: 8px 16px;
  border-radius: 20px;
  border: none;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.2s;

  background: ${(props) =>
    props.isSelected ? props.theme.colors.primary : '#f8f9fa'};
  color: ${(props) => (props.isSelected ? 'white' : '#495057')};

  &:hover {
    background: ${(props) =>
      props.isSelected ? props.theme.colors.primary : '#e9ecef'};
  }
`
