import React from 'react'
import styled from 'styled-components'
import AmexIcon from '../../assets/AmexIcon.svg'
import MasterCardIcon from '../../assets/MasterCardIcon.svg'
import VisaIcon from '../../assets/VisaIcon.svg'
import DiscoverCardIcon from '../../assets/DiscoverCardIcon.svg'

export const CardInfo = ({cards, selectedCard, handleCardClick}) => {
  const foundCard = cards?.find((card) => card.id === selectedCard)

  React.useEffect(() => {
    if (!foundCard) {
      handleCardClick('table')
    }
  }, [foundCard, handleCardClick])

  if (!foundCard || !foundCard.cards) return null

  const getCardIcon = (cardBrand) => {
    switch (cardBrand.toUpperCase()) {
      case 'VISA':
        return VisaIcon
      case 'MASTERCARD':
        return MasterCardIcon
      case 'AMEX':
        return AmexIcon
      case 'DISCOVER':
        return DiscoverCardIcon
      default:
        return null
    }
  }

  return (
    <CardsContainer>
      {foundCard.cards.map((card) => {
        const cardMonth = String(card.exp_month).padStart(2, '0')
        const cardYear = String(card.exp_year).slice(-2)

        return (
          <CardContainer key={card.id}>
            <CardContent>
              <CardBrandContainer>
                <img
                  src={getCardIcon(card.card_brand)}
                  alt={`${card.card_brand} Icon`}
                  style={{width: '65px'}}
                />
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '4px',
                  }}>
                  <CardDetails>
                    {card.card_brand.charAt(0).toUpperCase() +
                      card.card_brand.slice(1).toLowerCase()}{' '}
                    ending in {card.last_4}
                  </CardDetails>
                  <ExpirationDate>
                    Exp. date {cardMonth}/{cardYear}
                  </ExpirationDate>
                </div>
              </CardBrandContainer>
            </CardContent>
          </CardContainer>
        )
      })}
    </CardsContainer>
  )
}

const CardsContainer = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const CardContainer = styled.article`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 16px;
  width: 100%;
  border: 1px solid #eaedf5;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  margin-bottom: 12px;
  padding: 20px;
`

const CardContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

const CardDetails = styled.h2`
  margin: 0;
  font-weight: 500;
`

const ExpirationDate = styled.p`
  color: #717d96;
  font-size: 14px;
  margin: 0;
`

const CardBrandContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`
