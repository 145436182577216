import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import BackButton from '../../components/BackButton'
import {FaPlus} from 'react-icons/fa6'
import styled from 'styled-components'
import {useHistory} from 'react-router-dom'
import {PrimaryButton} from '../../components/Button'

const useStyles = makeStyles((theme) => ({
  header: {
    width: '100%',
    marginBottom: '0.5rem',
  },
  topContainer: {
    textAlign: 'center',
  },
}))

const TopContainerComponent = ({
  handleCardClick,
  setModalOpen,
  setFilterValue,
  showTopContainer,
}) => {
  const classes = useStyles()
  const history = useHistory()

  const handleAddCard = () => {
    setFilterValue('all')
    setModalOpen(true)
    handleCardClick('new-card')
  }

  return (
    <TopContainer className={classes.topContainer}>
      <TopText>
        <Typography variant="h1" className={classes.header}>
          Credit Cards
        </Typography>
        {showTopContainer && (
          <BackButton
            click={() => history.push('/dashboard')}
            style={{fontWeight: 'bold'}}
          />
        )}
      </TopText>
      {showTopContainer && (
        <PrimaryButton
          onClick={handleAddCard}
          style={{width: '130px', height: '50px', fontWeight: 'bold'}}>
          <FaPlus size={20} style={{marginRight: '10px'}} />
          <span>ADD CARD</span>
        </PrimaryButton>
      )}
    </TopContainer>
  )
}

export default TopContainerComponent

const TopContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

const TopText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`
