import React from 'react'
import styled from 'styled-components'
import {SearchNormal1} from 'iconsax-react'

export const Search = ({
  value,
  onChange,
  placeholder = 'Search...',
  ariaLabel = 'Search',
}) => {
  return (
    <SearchContainer role="search">
      <SearchNormal1 size={25} color="#858D9D" aria-hidden="true" />
      <SearchInput
        type="search"
        placeholder={placeholder}
        value={value}
        style={{color: '#858D9D'}}
        onChange={(e) => onChange(e.target.value)}
        aria-label={ariaLabel}
      />
    </SearchContainer>
  )
}

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  background: white;
  padding: 8px 16px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid #e0e2e7;
  height: 45px;
  flex: 1;
`

const SearchInput = styled.input`
  border: none;
  outline: none;
  width: 100%;
  font-size: 14px;
  padding: 8px 0;
  margin-left: 10px;
  &::placeholder {
    color: #adb5bd;
  }
`
