import {ca} from 'date-fns/locale'
import {
  ADD_CARD,
  ADD_CARD_SUCCESS,
  UPDATE_SELECTED_CARD,
  GET_ALL_CARDS,
  GET_ALL_CARDS_SUCCESS,
  DELETE_CARD,
  DELETE_CARD_SUCCESS,
} from '../actions/types'

function cardsDataReducer(state = {}, action = {}) {
  switch (action.type) {
    case ADD_CARD: {
      return {
        ...state,
        [action.payload]: action.payload,
      }
    }
    case ADD_CARD_SUCCESS: {
      const updatedCustomers = [action.payload, ...state.customers]

      return {
        ...state,
        customers: updatedCustomers,
        cardsFetched: false,
      }
    }

    case GET_ALL_CARDS: {
      return {...state}
    }
    case GET_ALL_CARDS_SUCCESS: {
      return {...state, ...action.payload}
    }
    case UPDATE_SELECTED_CARD: {
      const clickedId = action.payload
      if (!clickedId) {
        return {
          ...state,
          selectedCard: null,
        }
      }

      const selectedCard = state.customers.find((card) => card.id === clickedId)
      const selectedCardId = selectedCard ? selectedCard.id : null

      return {
        ...state,
        selectedCard: selectedCardId,
      }
    }

    case DELETE_CARD: {
      return state
    }
    case DELETE_CARD_SUCCESS: {
      const {card_id} = action.payload
      const updatedCustomers = state.customers.filter((customer) => {
        return !(
          customer.cards &&
          customer.cards.length > 0 &&
          customer.cards.some((card) => card.id === card_id)
        )
      })

      return {
        ...state,
        customers: updatedCustomers,
        cardsFetched: false,
        selectedCard: null,
      }
    }

    default:
      return state
  }
}

export default cardsDataReducer
