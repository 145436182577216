import {put, takeLatest, select} from 'redux-saga/effects'
import {toast} from '../../components/ToastMessage'

import {
  CREATE_PHARMACY,
  START_LOADING,
  STOP_LOADING,
  FETCH_DRIVERS,
  FETCH_STORES,
  ADMIN_FETCH_STORE,
  EDIT_ADMIN_FETCH_STORE,
  ADD_DRIVER,
  DELETE_DRIVERS,
  DELETE_DRIVERS_SUCCESS,
} from '../actions/types'
import {showError} from '../actions'

import {
  API_STORE_ENDPOINT,
  API_REPORTS_ENDPOINT,
  API_DRIVERS_ENDPOINT,
} from '../../constants'
import {request} from '../../utils/api'
import {getToken} from '../utils'

import {
  fetchDriversSuccess,
  fetchStoresSuccess,
  addDriverSuccess,
  deleteDriversSuccess,
} from '../actions'

export function* createPharmacy({payload}) {
  const token = yield select(getToken)
  const reqOpt = {
    method: 'POST',
    url: API_STORE_ENDPOINT,
    token,
    body: payload.data,
    headers: {
      'Content-Type': 'application/json',
    },
    stringify: true,
  }
  try {
    yield put({type: START_LOADING})
    const store = yield request(reqOpt)
    yield put({type: STOP_LOADING})
    alert(`Successfully created store "${store.storeNumber}"`)
    payload.callback()
  } catch (error) {
    yield put({type: STOP_LOADING})
    yield put(showError(error))
  }
}

export function* fetchDrivers({payload}) {
  const token = yield select(getToken)
  const reqOpt = {
    method: 'GET',
    url: API_DRIVERS_ENDPOINT,
    token,
    headers: {
      'Content-Type': 'application/json',
    },
  }
  try {
    yield put({type: START_LOADING})
    const drivers = yield request(reqOpt)
    yield put(fetchDriversSuccess(drivers))
    yield put({type: STOP_LOADING})
  } catch (error) {
    yield put({type: STOP_LOADING})
    yield put(showError(error))
  }
}

export function* fetchStores({payload}) {
  const token = yield select(getToken)
  const reqOpt = {
    method: 'GET',
    url: `${API_REPORTS_ENDPOINT}/stores`,
    token,
    headers: {
      'Content-Type': 'application/json',
    },
  }
  try {
    yield put({type: START_LOADING})
    const stores = yield request(reqOpt)
    yield put(fetchStoresSuccess(stores))
    yield put({type: STOP_LOADING})
  } catch (error) {
    yield put({type: STOP_LOADING})
    // yield put(showError(error))
  }
}

export function* adminFetchStore({payload}) {
  const token = yield select(getToken)
  const reqOpt = {
    method: 'GET',
    url: `${API_STORE_ENDPOINT}/${payload.data.storeId}`,
    token,
    headers: {
      'Content-Type': 'application/json',
    },
  }
  try {
    yield put({type: START_LOADING})
    const adminStoreInfo = yield request(reqOpt)
    payload.data.callback(adminStoreInfo)
    yield put({type: STOP_LOADING})
  } catch (error) {
    yield put({type: STOP_LOADING})
    yield put(showError(error))
  }
}

export function* editAdminFetchStore({payload}) {
  const token = yield select(getToken)
  const reqOpt = {
    method: 'PUT',
    url: `${API_STORE_ENDPOINT}`,
    token,
    body: payload.data.data,
    headers: {
      'Content-Type': 'application/json',
    },
    stringify: true,
  }
  try {
    yield put({type: START_LOADING})
    yield request(reqOpt)
    yield put({type: STOP_LOADING})
    alert(`Successfully edited store "${payload.data.data.storeNumber}"`)
    payload.data.callback()
  } catch (error) {
    yield put({type: STOP_LOADING})
    yield put(showError(error))
  }
}

export function* addDriver({payload}) {
  const token = yield select(getToken)
  const reqOpt = {
    method: 'POST',
    url: API_DRIVERS_ENDPOINT,
    token,
    body: payload,
    headers: {
      'Content-Type': 'application/json',
    },
    stringify: true,
  }

  try {
    yield put({type: START_LOADING})
    const driver = yield request(reqOpt)
    yield put(addDriverSuccess(driver))
    toast.success(`Successfully added driver ${driver.driverName}`)
    yield put({type: STOP_LOADING})
  } catch (error) {
    yield put({type: STOP_LOADING})
    toast.error(error.message)
  }
}

export function* deleteDrivers({payload}) {
  const token = yield select(getToken)
  const reqOpt = {
    method: 'DELETE',
    url: API_DRIVERS_ENDPOINT,
    token,
    body: {
      ids: payload,
    },
    headers: {
      'Content-Type': 'application/json',
    },
    stringify: true,
  }
  try {
    yield put({type: START_LOADING})
    const results = yield request(reqOpt)

    yield put(deleteDriversSuccess(results))
    yield put({type: STOP_LOADING})

    const {successful} = results.results

    for (const driver of successful) {
      toast.success(`Successfully deleted driver ${driver.name}`)
    }
  } catch (error) {
    yield put({type: STOP_LOADING})
    yield put(showError(error))
  }
}

export function* watchAdmin() {
  yield takeLatest(CREATE_PHARMACY, createPharmacy)
  yield takeLatest(FETCH_DRIVERS, fetchDrivers)
  yield takeLatest(FETCH_STORES, fetchStores)
  yield takeLatest(ADMIN_FETCH_STORE, adminFetchStore)
  yield takeLatest(EDIT_ADMIN_FETCH_STORE, editAdminFetchStore)
  yield takeLatest(ADD_DRIVER, addDriver)
  yield takeLatest(DELETE_DRIVERS, deleteDrivers)
}

export default watchAdmin
