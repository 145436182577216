import {configureStore} from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'
import {
  persistStore,
  persistReducer,
  createMigrate,
  createTransform,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import rootReducer from './reducers'
import rootSaga from './sagas'
import {getInitialStartAndEndDate} from '../utils/dates'
import {DeliveryFilterOption} from '../hooks/useDeliveryDateFilter'
import {migrations} from './migrations'

const {sevenDaysInFuture, threeWeeksInPast} = getInitialStartAndEndDate()

const defaultState = {
  user: {
    isLoading: false,
    deliveries: [],
    userInfo: {},
    isAuthenticated: false,
    token: '',
    expiresAt: Math.trunc(new Date().getTime() / 1000),
  },
  deliveries: {
    history: [],
    newDeliveryData: {},
    dateFilter: {
      startDate: threeWeeksInPast,
      endDate: sevenDaysInFuture,
    },
    onHoldOrders: [],
    searchTerm: '',
    activeTimeline: DeliveryFilterOption.ALL,
    isNarcotics: false,
    hasMoreDeliveries: true,
  },
  orders: {orders: [], order: {}, hasMoreOrders: true},
  app: {error: undefined, loading: false, currentAdminPage: 'stores'},
  admin: {stores: [], drivers: []},
  voice: [],
  payment: {latestTransaction: {}},
  cards: {customers: [], cardsFetched: false},
}

// Custom transform to clear deliveries.history on rehydration
const deliveriesTransform = createTransform(
  (inboundState) => inboundState, // Leave unchanged on persist

  (outboundState) => {
    // Ensure history is set to an empty array on rehydration
    if (outboundState) {
      return {
        ...outboundState,
        history: [], // Reset history to an empty array
      }
    }
    return outboundState
  },
  {whitelist: ['deliveries']}, // Only apply to 'deliveries' slice
)

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user', 'deliveries', 'admin', 'voice'],
  version: 3,
  migrate: createMigrate(migrations, {debug: false}),
  transforms: [deliveriesTransform], // Add the custom transform here
}

const sagaMiddleware = createSagaMiddleware()
const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
  reducer: persistedReducer,
  preloadedState: defaultState,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({serializableCheck: false}).concat(sagaMiddleware),
  devTools: process.env.NODE_ENV !== 'production',
})

sagaMiddleware.run(rootSaga)

let persistor = persistStore(store)

export {store, persistor}
