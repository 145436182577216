import React from 'react'
import styled from 'styled-components'
import InputLabel from '@material-ui/core/InputLabel'
import SaveAltIcon from '@material-ui/icons/SaveAlt'
import {connect} from 'react-redux'

import DatePicker from '../../components/DatePicker'
import {PrimaryButton} from '../../components/Button'
import {
  downloadDeliveryCsv,
  downloadDriverReport,
  downloadStoreReport,
} from '../../redux/actions'
import theme from '../../theme'
import {updateDateFilter} from '../../redux/actions/deliveries'

const DateFilter = ({
  downloadDeliveryCsv,
  downloadDriverReport,
  downloadStoreReport,
  info,
  buttonText = 'Download',
  action = '',
  disabled = false,
  dateFilter,
  updateDateFilter,
}) => {
  const handleDateChange = (name) => (newdate) => {
    updateDateFilter({...dateFilter, [name]: new Date(newdate).toISOString()})
  }

  const handleDownload = () => {
    switch (action) {
      case 'delivery':
        downloadDeliveryCsv(dateFilter)
        break
      case 'store':
        downloadStoreReport({...dateFilter, info})
        break
      case 'driver':
        downloadDriverReport({...dateFilter, info})
        break
      default:
        break
    }
  }

  const deliveryBtn = {
    background: 'none',
    color: theme.colors.primaryLight,
    boxShadow: 'none',
  }

  const generateReportBtn = {padding: '1.5rem'}

  return (
    <Container>
      <DatePickerContainer>
        <DatePicker
          label="Start Date"
          name="startDate"
          date={dateFilter['startDate']}
          value={dateFilter['startDate']}
          onChange={handleDateChange('startDate')}
          autoOk
          style={{width: '100%'}}
        />
        <DatePicker
          label="End Date"
          name="endDate"
          date={dateFilter['endDate']}
          onChange={handleDateChange('endDate')}
          value={dateFilter['endDate']}
          autoOk
          style={{width: '100%'}}
        />
      </DatePickerContainer>
      <PrimaryButton
        type="button"
        onClick={handleDownload}
        style={action === 'delivery' ? deliveryBtn : generateReportBtn}
        disabled={disabled}>
        {action === 'delivery' ? <SaveIcon /> : <></>}
        {buttonText}
      </PrimaryButton>
    </Container>
  )
}

const mapStateToProps = ({deliveries = []}) => ({
  dateFilter: deliveries.dateFilter,
})

const mapDispatchToProps = (dispatch) => ({
  downloadDeliveryCsv: ({startDate, endDate}) =>
    dispatch(downloadDeliveryCsv({startDate, endDate})),
  downloadDriverReport: ({startDate, endDate, info}) =>
    dispatch(downloadDriverReport({startDate, endDate, info})),
  downloadStoreReport: ({startDate, endDate, info}) =>
    dispatch(downloadStoreReport({startDate, endDate, info})),
  updateDateFilter: (dates) => dispatch(updateDateFilter(dates)),
})

export default connect(mapStateToProps, mapDispatchToProps)(DateFilter)

const SaveIcon = styled(SaveAltIcon)`
  margin-right: 5px;
  fill: ${({theme}) => theme.colors.iconfill};
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`

const DatePickerContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;
  margin-bottom: 25px;

  > * {
    flex: 1;
  }
`

const CenterContainer = styled.div`
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
`
