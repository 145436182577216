import React, {useState, useRef, useEffect} from 'react'
import {useForm, Controller} from 'react-hook-form'
import {makeStyles} from '@material-ui/core/styles'
import {yupResolver} from '@hookform/resolvers/yup'
import {object, string} from 'yup'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'
import TextField from '../../components/Textfield'
import {PrimaryButton, SecondaryButton} from '../../components/Button'
import Checkbox from '../../components/Checkbox'
import LoadingOrange from '../../components/LoadingOrange'
import AmexIcon from '../../assets/AmexIcon.svg'
import MasterCardIcon from '../../assets/MasterCardIcon.svg'
import VisaIcon from '../../assets/VisaIcon.svg'

const useStyles = makeStyles(() => ({
  normalizeLabelSpace: {
    whiteSpace: 'normal',
  },
}))

const CustomSquareStyle = {
  '.input-container.is-focus': {
    borderWidth: '1px',
  },
}

const newCreditCardSchema = object().shape({
  nickname: string(),
  givenName: string().required('Required'),
  familyName: string().required('Required'),
})

const initializeCard = async (payments) => {
  const card = await payments.card({style: CustomSquareStyle})
  const cardContainer = document.querySelector('#card-container')
  cardContainer.innerHTML = ''
  await card.attach('#card-container')
  return card
}

export const AddCard = ({
  addCard,
  userInfo = {},
  loading,
  locationId,
  closeAddCardModal,
}) => {
  const [card, setCard] = useState(null)
  const [squareLoading, setSquareLoading] = useState(false)
  const [storeApproval, setStoreApproval] = useState(false)
  const classes = useStyles()
  const nicknameInputRef = useRef()
  const givenNameInputRef = useRef()
  const familyNameInputRef = useRef()

  const payments = window.Square.payments(
    process.env.REACT_APP_SQUARE_APP_ID,
    locationId,
  )

  const {
    control,
    getValues,
    handleSubmit,
    trigger,
    formState: {errors},
  } = useForm({
    defaultValues: {
      nickname: '',
      givenName: '',
      familyName: '',
    },
    resolver: yupResolver(newCreditCardSchema),
  })

  const tokenize = async (paymentMethod) => {
    if (process.env.REACT_APP_STAGE === 'development') {
      return 'cnon:card-nonce-ok'
    }

    const tokenResult = await paymentMethod.tokenize()

    if (tokenResult.status === 'OK') {
      return tokenResult.token
    }

    throw new Error(
      `Tokenization errors: ${JSON.stringify(tokenResult.errors)}`,
    )
  }

  const verifyBuyer = async (payments, token) => {
    if (process.env.REACT_APP_STAGE === 'development') {
      return null
    }

    const {nickname, givenName, familyName} = getValues()
    const verificationDetails = {
      billingContact: {
        givenName,
        familyName,
        nickname,
      },
      intent: 'STORE',
    }

    const verificationResults = await payments.verifyBuyer(
      token,
      verificationDetails,
    )
    return verificationResults.token
  }

  const handleStoreCardSubmission = async (event, card, payments) => {
    event.preventDefault()

    try {
      setSquareLoading(true)
      await trigger()
      const token = await tokenize(card)
      const verificationToken = await verifyBuyer(payments, token)

      const {nickname, givenName, familyName} = getValues()

      const cardBody = {
        source_id: token,
        idempotency_key: window.crypto.randomUUID(),
      }

      if (verificationToken) {
        cardBody.verification_token = verificationToken
      }

      const body = {
        customerBody: {
          nickname,
          given_name: givenName,
          family_name: familyName,
        },
        cardBody,
      }

      const {error} = await addCard({body})

      if (error) {
        console.error('Failed to add card:', error)
        return
      }

      closeAddCardModal()
    } catch (error) {
      console.error(error)
    } finally {
      setSquareLoading(false)
    }
  }

  useEffect(() => {
    const init = async () => {
      let card = await initializeCard(payments)
      setCard(card)

      return () => {
        if (card) {
          card.detach()
        }
      }
    }
    init()
  }, [payments])

  const storeNumber =
    userInfo?.['https://scriptrunner.com/store_number'] || 'Your store'

  return (
    <AddCardContainer>
      <form
        id="create-customer"
        style={{width: '100%'}}
        errors={errors}
        onSubmit={handleSubmit()}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignContent: 'center',
            marginBottom: '24px',
          }}>
          <HeaderTypography variant="h1" style={{marginBottom: '0px'}}>
            Add new card
          </HeaderTypography>
          <Center>
            <img
              src={AmexIcon}
              alt="Amex Icon"
              style={{
                width: '100%',
                maxWidth: '30px',
                marginRight: '12px',
              }}
            />
            <img
              src={MasterCardIcon}
              alt="MasterCard Icon"
              style={{
                width: '100%',
                maxWidth: '30px',
                marginRight: '12px',
              }}
            />
            <img
              src={VisaIcon}
              alt="Visa Icon"
              style={{width: '100%', maxWidth: '30px'}}
            />
          </Center>
        </div>

        <div style={{marginBottom: '24px'}}>
          <Controller
            name="nickname"
            control={control}
            render={(props) => (
              <TextField
                label="Nickname (optional)"
                inputRef={nicknameInputRef}
                onChange={(e) => props.onChange(e)}
                value={props.value}
              />
            )}
          />
        </div>
        <Row>
          <Controller
            name="givenName"
            control={control}
            render={(props) => (
              <TextField
                label="First Name"
                inputRef={givenNameInputRef}
                onChange={(e) => props.onChange(e)}
                error={errors?.givenName}
                helperText={errors?.givenName && 'Required'}
                value={props.value}
              />
            )}
          />
          <Controller
            name="familyName"
            control={control}
            render={(props) => (
              <TextField
                label="Last Name"
                inputRef={familyNameInputRef}
                onChange={(e) => props.onChange(e)}
                error={errors?.familyName}
                helperText={errors?.familyName && 'Required'}
                value={props.value}
              />
            )}
          />
        </Row>
        <CCContainer>
          <div
            id="card-container"
            style={{minWidth: '500px', marginTop: '24px'}}
          />
          <Checkbox
            onChange={() => setStoreApproval(!storeApproval)}
            defaultChecked={storeApproval}
            label={`${storeNumber} authorizes Scriptrunner Innovations Inc to charge the customers credit card on behalf of ${storeNumber}, indicated in this authorization form according to the terms above. This payment authorization is valid until cancelled. The customer certifies that they are an authorized user of this credit card and that they will not dispute the payment with their credit card company so long as the transaction corresponds to the terms indicated in this form.`}
            value={storeApproval}
            containerStyles={{
              marginBottom: '24px',
              alignItems: 'flex-start',
            }}
            labelStyles={classes.normalizeLabelSpace}
            labelPlacement="end"
          />
          <ButtonGroup>
            <SecondaryButton
              id="cancel-button"
              onClick={closeAddCardModal}
              style={{width: '170px'}}>
              Cancel
            </SecondaryButton>
            <PrimaryButton
              disabled={squareLoading || !storeApproval}
              id="card-button"
              onClick={async (e) => {
                const givenNameState = await trigger('givenName')
                const familyNameState = await trigger('familyName')

                if (givenNameState && familyNameState) {
                  handleStoreCardSubmission(e, card, payments)
                }
              }}
              style={{width: '170px'}}>
              Proceed
            </PrimaryButton>
            {loading && <LoadingOrange />}
          </ButtonGroup>
        </CCContainer>
      </form>
    </AddCardContainer>
  )
}

const AddCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  flex: 1;
  padding: 24px;
`

const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  & > *:first-child {
    margin-right: 2rem;
  }
  margin-bottom: 24px;
`

const CCContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  & > *:first-child {
    margin-right: 2rem;
    flex-shrink: 1;
  }
`

const HeaderTypography = styled(Typography)`
  margin-bottom: 24px;
`

const Center = styled.div`
  display: flex;
  align-items: center;
`
