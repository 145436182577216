import React from 'react'
import styled from 'styled-components'
import {makeStyles} from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import {DialogTitle} from '@material-ui/core'
import ScriptRunnerCard from '../../assets/script-runner-card.svg'
import {PrimaryButton} from '../../components/Button'
import {getLatestTransaction, updatePurchaseModalFlag} from '../../redux/actions'
import { connect } from 'react-redux'

const DialogContainer = styled.div`
  padding: 2.4rem;
  width: 43.6em;
`

const DialogDescription = styled.p`
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2rem;
  color: '#000000';
`

const ImageContainer = styled.div`
  display: flex;
  padding: 6rem 9rem 13rem 9rem;
`

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    fontWeight: 500,
    fontSize: '2rem',
    lineHeight: '3rem',
    color: theme.colors.textPrimary,
    padding: 0,
  },
}))

const ScriptRunnerCardLoadingModal = ({handleClose, isCharge=true, onClick, orderId, updatePurchaseModalFlag, getLatestTransaction}) => {
  const classes = useStyles()

  const handleDialogClose = () => {
    if (!isCharge) {
      handleClose()
      return
    }
    getLatestTransaction({
      orderReference: orderId,
      latestTransactionType: 'CHARGE',
      hideError: true,
    })
    updatePurchaseModalFlag({
      isModalOpen: false,
      orderId: null,
      successCb: () => handleClose(),
    })
  }

  return (
    <DialogContainer style={{textAlign: 'center'}}>
      <DialogTitle className={classes.dialogTitle} disableTypography >
        {isCharge ? 'Charge ScriptRunner Card' : 'Refund ScriptRunner Card'}
        <CloseIcon data-testid="close-icon" color="primary" onClick={handleDialogClose} />
      </DialogTitle>
      <DialogDescription style={{textAlign: 'left'}}>
        Use the external POS to scan prescriptions and any OTC items attached to
        this order.
      </DialogDescription>
      <ImageContainer>
        <img src={ScriptRunnerCard} alt="ScriptRunner Card" style={{margin: 'auto'}} />
      </ImageContainer>
      <PrimaryButton style={{maxWidth: '17.1rem'}} onClick={onClick}>{isCharge ? 'View Payment' : 'View Refund'}</PrimaryButton>
    </DialogContainer>
  )
}

const mapDispatchToProps = (dispatch) => ({
  updatePurchaseModalFlag: (payload) => dispatch(updatePurchaseModalFlag(payload)),
  getLatestTransaction: (payload) => dispatch(getLatestTransaction(payload)),
})


export default connect(null, mapDispatchToProps)(ScriptRunnerCardLoadingModal)
