import React from 'react'
import {PrimaryButton} from '../../components/Button'
import styled from 'styled-components'
import {FaEllipsisH} from 'react-icons/fa'
import {formatPhoneNumber} from '../../utils/common'

export const DriverCard = ({
  driver,
  selectedDrivers,
  handleDriverSelect,
  handleActionsClick,
}) => {
  return (
    <DriverCardContainer
      key={driver.driverId}
      selected={selectedDrivers.includes(driver.driverId)}>
      <DriverCheckbox
        type="checkbox"
        checked={selectedDrivers.includes(driver.driverId)}
        onChange={() => handleDriverSelect(driver.driverId)}
      />
      <DriverInfo>
        <h4 style={{maxWidth: '150px'}}>{driver.driverName}</h4>
        <p>{formatPhoneNumber(driver.driverPhone) || 'No phone number'}</p>
        <p>{driver.emailAddress || 'No email'}</p>
        <p>{driver.driverType || 'No type'}</p>
      </DriverInfo>
      <PrimaryButton
        onClick={() => handleActionsClick(driver)}
        style={{width: '100%'}}>
        <FaEllipsisH size={18} style={{marginRight: '10px'}} />
        Actions
      </PrimaryButton>
    </DriverCardContainer>
  )
}

const DriverCardContainer = styled.div`
  position: relative;
  background: white;
  border-radius: 12px;
  padding: 40px 20px;
  box-shadow: 0px 4px 21px rgba(0, 0, 0, 0.07);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  border: 2px solid
    ${({selected, theme}) => (selected ? theme.colors.primary : 'transparent')};
`

const DriverInfo = styled.div`
  margin-bottom: 16px;

  h4 {
    font-size: 18px;
    margin: 0 0 8px 0;
  }

  p {
    margin: 4px 0;
    color: ${({theme}) => theme.colors.label};
  }
`
const DriverCheckbox = styled.input`
  width: 20px;
  height: 20px;
  position: absolute;
  top: 10px;
  left: 10px;
  cursor: pointer;
`
