import React from 'react'
import styled from 'styled-components'
import {CardInfo} from './CardInfo'
import {DeleteCards} from './DeleteCards'
import {getFilteredCards} from './GetFilteredCards'
import BackButton from '../../components/BackButton'

export const Table = ({
  cards,
  selectedCard,
  handleCardClick,
  filterValue,
  searchTerm,
}) => {
  const foundCard = cards.find((card) => card.id === selectedCard)
  const filteredCards = getFilteredCards(cards, filterValue, searchTerm)
  const showTable = !selectedCard

  const handleBack = () => {
    handleCardClick(null)
  }

  return (
    <main>
      <TableContainer>
        {showTable ? (
          <div role="region" aria-label="Credit Cards Table">
            <StyledTable>
              <thead>
                <tr>
                  <th scope="col">NAME</th>
                  <th scope="col">NICKNAME</th>
                </tr>
              </thead>
              <tbody>
                {filteredCards.map((card) => (
                  <tr key={card.id} onClick={() => handleCardClick(card.id)}>
                    <td>{`${card.given_name} ${card.family_name}`}</td>
                    <td>{card.nickname || '-'}</td>
                  </tr>
                ))}
              </tbody>
            </StyledTable>
          </div>
        ) : foundCard ? (
          <section
            style={{
              marginTop: '40px',
            }}>
            <CardDetailsHeader>
              <BackButton click={handleBack} />
              <h2>{`${foundCard.given_name} ${foundCard.family_name}`}</h2>
              <DeleteCards card={foundCard} />
            </CardDetailsHeader>
            <CardInfo
              cards={cards}
              selectedCard={selectedCard}
              handleCardClick={handleCardClick}
            />
          </section>
        ) : null}
      </TableContainer>
    </main>
  )
}

const TableContainer = styled.div`
  width: 100%;
  border-radius: 12px;
`

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  background: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  overflow: hidden;

  th,
  td {
    padding: 12px 16px;
    text-align: left;
  }

  th {
    background: white;
    font-weight: 600;
    color: #495057;
    border-bottom: 1px solid #e0e2e7;
  }

  tbody tr {
    cursor: pointer;
    transition: background-color 0.2s;

    &:hover {
      background-color: #f8f9fa;
    }
  }
`

const CardDetailsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
`
