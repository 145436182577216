const isCardExpired = (card) => {
  const today = new Date()
  const currentYear = today.getFullYear()
  const currentMonth = today.getMonth() + 1

  return card.cards?.some((card) => {
    return (
      card.exp_year < currentYear ||
      (card.exp_year === currentYear && card.exp_month < currentMonth)
    )
  })
}

const isCardAlmostExpired = (card) => {
  const today = new Date()
  const currentYear = today.getFullYear()
  const currentMonth = today.getMonth() + 1

  return card.cards?.some((card) => {
    return card.exp_year === currentYear && card.exp_month === currentMonth
  })
}

export const getFilteredCards = (cards, filterValue, searchTerm = '') => {
  return cards.filter((card) => {
    const searchLower = searchTerm.toLowerCase()
    const fullName = `${card.given_name} ${card.family_name}`.toLowerCase()
    const nickname = (card.nickname || '').toLowerCase()
    const matchesSearch =
      fullName.includes(searchLower) || nickname.includes(searchLower)

    if (filterValue === 'expired') {
      return matchesSearch && isCardExpired(card)
    }

    if (filterValue === 'almost-expired') {
      return matchesSearch && isCardAlmostExpired(card)
    }

    return matchesSearch
  })
}
