import {put, takeLatest, select} from 'redux-saga/effects'
import {toast} from '../../components/ToastMessage'
import {
  GET_ALL_CARDS,
  ADD_CARD,
  DELETE_CARD,
  START_LOADING,
  STOP_LOADING,
} from '../actions/types'

import {
  addCardSuccess,
  getAllCardsSuccess,
  deleteCardSuccess,
  showError,
} from '../actions'

import {request} from '../../utils/api'
import {getToken} from '../utils'
import {API_CARDS_ENDPOINT} from '../../constants'

export function* addCard(action) {
  const {
    payload: {body},
    resolve,
  } = action
  const token = yield select(getToken)

  const reqOpt = {
    method: 'POST',
    url: `${API_CARDS_ENDPOINT}`,
    token,
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
    },
  }

  try {
    yield put({type: START_LOADING})
    const cardData = yield request(reqOpt)
    yield put(addCardSuccess(cardData?.customer))
    yield put({type: STOP_LOADING})

    toast.success('Card added successfully')
    resolve({success: true, data: cardData})
  } catch (error) {
    yield put({type: STOP_LOADING})
    toast.error(error.message || 'Failed to add card')
    resolve({success: false, error: error.message || 'Add card failed'})
  }
}

export function* getAllCards({payload: {limit = 100, cursor = null}}) {
  const token = yield select(getToken)
  let queries = `?limit=${limit}`

  if (cursor) {
    queries += `&cursor=${cursor}`
  }
  const reqOpt = {
    method: 'GET',
    url: `${API_CARDS_ENDPOINT}${queries}`,
    token,
    headers: {
      'Content-Type': 'application/json',
    },
  }
  try {
    yield put({type: START_LOADING})
    const cardData = yield request(reqOpt)
    yield put(
      getAllCardsSuccess({customers: cardData.customers, cardsFetched: true}),
    )
    yield put({type: STOP_LOADING})
  } catch (error) {
    yield put({type: STOP_LOADING})
    yield put(showError(error))
  }
}

export function* deleteCard({payload: {card_id}}) {
  const token = yield select(getToken)
  const reqOpt = {
    method: 'DELETE',
    url: `${API_CARDS_ENDPOINT}/${card_id}`,
    token,
    headers: {
      'Content-Type': 'application/json',
    },
  }
  try {
    yield put({type: START_LOADING})
    const cardData = yield request(reqOpt)
    yield put(deleteCardSuccess({card_id, ...cardData}))
    yield put({type: STOP_LOADING})
    toast.success('Card deleted successfully')
  } catch (error) {
    yield put({type: STOP_LOADING})
    yield put(showError(error))
    toast.error('Failed to delete card')
  }
}

export function* watchCards() {
  yield takeLatest(ADD_CARD, addCard)
  yield takeLatest(GET_ALL_CARDS, getAllCards)
  yield takeLatest(DELETE_CARD, deleteCard)
}

export default watchCards
